/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { Input, Button } from '@3pp/ui-library';
import { fetchData, nominalToThousand, eventTracker } from '@3pp/utils';
import queryString from 'query-string';
import Cookies from 'js-cookie';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import Spinner from 'components/common/Spinner';
// import ErrorPage from 'components/ErrorPage'
import { FlashMessage } from '@3pp/ui-library';
import Account from '../Account';
import customHeaders from 'utils/customHeaders';

import { Label, Row, Asterisks, CtaContainer, Overlay } from '../style';

const Profesi = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [aboveNisab, setAboveNisab] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const [monthlyIncome, setMonthlyIncome] = useState('');
  const [othersIncome, setOthersIncome] = useState('');
  const [debt, setDebt] = useState('');
  const [amount, setAmount] = useState(null);
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(true);
  const queryParams = queryString.parse(props.location.search);
  const accountRef = useRef(null);
  const inputStyle = {
    background: '#fff',
    borderRadius: 3,
    fontSize: 14,
    lineHeight: '19px',
    padding: '14px 18px',
    border: '1px solid #E8E9EB',
  };
  useEffect(() => {
    let timeOut;
    if (showFlashMessage) {
      timeOut = setTimeout(() => {
        setShowFlashMessage(false);
      }, 2000);
    }

    return () => clearTimeout(timeOut);
  }, [showFlashMessage]);
  useEffect(() => {
    const getDanaToken = async () => {
      try {
        const { data } = await fetchData({
          url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/dana/token`,
          method: 'POST',
          body: {
            grantType: 'AUTHORIZATION_CODE',
            authCode: queryParams.authCode || queryParams.auth_code,
          },
          additionalHeaders: customHeaders,
        });
        const tokenResponse = data;
        if (Object.hasOwnProperty.call(tokenResponse[0], 'access_token')) {
          Cookies.set('auth_token_dana', tokenResponse[0].access_token, { expires: 1 });
          setIsError(false);
          setLoading(false);
        } else {
          Cookies.remove('auth_token_dana');

          setLoading(false);
          setIsError(true);
        }
      } catch (error) {
        throw error;
      }
    };
    if (!queryParams.authCode && !queryParams.auth_code) {
      Cookies.remove('auth_token_dana');
      setIsError(true);
      setLoading(false);
    } else if (loading) {
      getDanaToken();
    }
  }, [loading, queryParams.authCode, queryParams.auth_code]);

  const validateForm = () => {
    return monthlyIncome === '';
  };
  const calculateZakat = async () => {
    setIsLoading(true);
    const response = await fetchData({
      method: 'POST',
      url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/zakat/calculator/profession`,
      body: {
        monthly_income: Number(monthlyIncome.split('.').join('')),
        other_income: Number(othersIncome.split('.').join('')),
        debt: Number(debt.split('.').join('')),
      },
      additionalHeaders: customHeaders,
    });
    if (response) {
      if (response.data[0].more_than_nisab) {
        setAboveNisab(true);
      } else {
        setAboveNisab(false);
      }
      setAmount(response.data[0].zakat_amount);
      setAccountModal(true);
    }
    setIsLoading(false);
  };
  if (isError && !loading && !queryParams.noAuth) {
    // return (
    //   <ErrorPage errorCode="500" />
    // )
  }
  return (
    <div ref={accountRef}>
      <FlashMessage
        colorType="blue"
        inProp={showFlashMessage}
        text="Hasil perhitungan zakat berhasil disalin"
        icon={<FontAwesomeIcon icon={faCheckCircle} />}
      />
      <strong style={{ display: 'block', marginBottom: 7 }}>
        Pendapatan <Asterisks>*</Asterisks>
      </strong>
      <Row>
        <Label>Gaji per bulan</Label>
        <Input
          placeholder="Masukkan gaji kamu"
          style={inputStyle}
          type="tel"
          value={monthlyIncome}
          onChangeVal={e => {
            setMonthlyIncome(nominalToThousand(e));
          }}
        />
      </Row>
      <Row>
        <Label>Pendapatan Lain</Label>
        <Input
          placeholder="Opsional, jika ada"
          style={inputStyle}
          type="tel"
          value={othersIncome}
          onChangeVal={e => {
            setOthersIncome(nominalToThousand(e));
          }}
        />
      </Row>
      <Row>
        <Label>Hitung / Cicilan</Label>
        <Input
          placeholder="Opsional, jika ada"
          style={inputStyle}
          type="tel"
          value={debt}
          onChangeVal={e => {
            setDebt(nominalToThousand(e));
          }}
        />
      </Row>
      <Overlay isOpen={accountModal} onClick={() => setAccountModal(false)} />
      <Account
        aboveNisab={aboveNisab}
        closeModal={() => setAccountModal(false)}
        isOpen={accountModal}
        history={props.history}
        showFlashMessage={() => setShowFlashMessage(true)}
        title="Hasil Perhitungan Zakat Profesi"
        nominal={amount || ''}
      />
      <CtaContainer>
        <Button
          disabled={validateForm()}
          backgroundColor="#3694E3"
          height="40px"
          fontSize="14px"
          margin="0"
          style={{
            borderRadius: 4,
            maxWidth: 480,
            margin: '0 auto',
          }}
          onClick={() => {
            eventTracker('button hitung kalkulator zakat dana');
            calculateZakat();
          }}
          label={isLoading ? <Spinner width={30} /> : 'HITUNG ZAKAT'}
        />
      </CtaContainer>
    </div>
  );
};

export default Profesi;
