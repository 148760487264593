/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react'
import HomeComponent from 'components/HomeComponent'
import customHeaders from 'utils/customHeaders'
import { fetchData } from '@3pp/utils'

const Home = props => {
  const [banners, setBanners] = useState(null)
  const [carouselCampaign, setCarouselCampaign] = useState(null)
  const [listCampaign, setListCampaign] = useState(null)
  useEffect(() => {
    const fetchBanner = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/banners`,
        method: 'GET',
        additionalHeaders: customHeaders,
      }).then(data => {
        setBanners(data.data)
      })
    }
    const fetchCampaign = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_CAROUSEL_CAMPAIGN}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      }).then(data => {
        setCarouselCampaign(data.data[0].data)
      })
    }
    const fetchListCampaign = () => {
      fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_LIST_CAMPAIGN}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      }).then(data => {
        setListCampaign(data.data[0].data)
      })
    }
    fetchListCampaign()
    fetchBanner()
    fetchCampaign()
  }, [])
  return (
    <HomeComponent
      router={props.router}
      banners={banners}
      carouselCampaign={carouselCampaign}
      listCampaign={listCampaign}
      {...props}
    />
  )
}

export default Home
