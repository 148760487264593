import React from 'react'
import PropTypes from 'prop-types'
import theme from 'constants/theme'
import { Button } from '@3pp/ui-library'
import { eventTracker } from '@3pp/utils'
import {
  NominalOptionsContainer,
  Nominal,
  Section,
  DonationLabel,
  FirstLetter,
} from './styles'
import DonationInput from '../DonationInput'

const ContributeNominal = ({
  amount,
  setAmount,
  onValueChange
}) => {
  const nominalOptions = [
    {
      value:10000,
      label:(<Nominal><FirstLetter>Rp10.</FirstLetter>000</Nominal>),
      active: amount === 10000,
    },
    {
      value:20000,
      label:(<Nominal><FirstLetter>Rp20.</FirstLetter>000</Nominal>),
      active: amount === 20000,
    },
    {
      value:50000,
      label:(<Nominal><FirstLetter>Rp50.</FirstLetter>000</Nominal>),
      active: amount === 50000,
    },
    {
      value:100000,
      label:(<Nominal><FirstLetter>Rp100.</FirstLetter>000</Nominal>),
      active: amount === 100000,
    },
  ]
  return (
    <React.Fragment>
      <Section>
        <DonationLabel>Donasi mulai dari Rp.1000</DonationLabel>
      </Section>
      <Section>
        <DonationInput
          label="Jumlah Donasi"
          id="contribute_inputfield_amount-donation"
          name="Donation"
          amount={amount}
          onChangeAmount={onValueChange}
          placeholder="0"
        />
        <NominalOptionsContainer>
          {nominalOptions.map(item => {
            return (
              <Button
                backgroundColor={item.active ? theme.color.primary : "#fff"}
                color={item.active ? "#fff" : "#3a3a3a"}
                style={{
                  fontSize: 16,
                  flexGrow: 1,
                  borderRadius: 4,
                  boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.23)',
                  fontWeight: 400
                }}
                margin="5px"
                width="45%"
                height="50px"
                key={item.value}
                onClick={() => {
                  eventTracker(`denom ${item.value} contribute dana`)
                  setAmount(item.value)
                }}
              >
                {item.label}
              </Button>
            )
          })}
        </NominalOptionsContainer>

      </Section>
    </React.Fragment>
  )
}

ContributeNominal.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  setAmount: PropTypes.func,
  onValueChange: PropTypes.func
}
export default ContributeNominal
