import React from 'react'
import { eventTracker } from '@3pp/utils'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styles from './styles.module.scss'

const Survey = () => {
  return (
    <div className={styles.survey}>
      <a
        onClick={() => eventTracker('card csat survey dana')}
        style={{ display: 'block' }}
        href="https://kitabisa.typeform.com/to/cwYjg8?utm_source=3pp_dana" title="Survey"
      >
        <LazyLoadImage
          alt="Kitabisa+"
          src="/uploads/survey.svg"
        />
      </a>
    </div>
  )
}

export default Survey
