import React from 'react'
import Slider from 'react-slick'
import ContentLoader from 'react-content-loader'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { eventTracker } from '@3pp/utils'

import styles from './styles.module.scss'
import 'slick-carousel/slick/slick.scss'

const Carousel = props => {
  const Loader = () => (
    <ContentLoader
      key="carousel"
      uniqueKey="carousel"
      speed={2}
      width={480}
      height={253}
      viewBox="0 0 480 253"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="10" y="1" rx="5" ry="5" width="450" height="250" />
      <rect x="470" y="1" rx="5" ry="5" width="18" height="250" />
    </ContentLoader>
  )
  const settings = {
    // eslint-disable-next-line react/display-name
    customPaging: function(i) {
      return (
        <span
          id={i}
          className="swiper-pagination-bullet"
          tabIndex={i}
          aria-label={`Go to slide ${i}`}>
          {i}
        </span>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    arrows: false,
    lazyLoad: false,
    slideToShow: 1,
    centerMode: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    centerPadding: '30px'
  }
  return (
    <div className={styles.carousel}>
      {
        (props.banners !== null) ?
          <Slider {...settings}>
            {props.banners.map((banner) => {
              return(
                <a
                  onClick={() => eventTracker('slider banner dana')}
                  title={banner.url}
                  key={banner.id}
                  href={banner.url}
                >
                  <LazyLoadImage
                    alt={banner.url}
                    width={328}
                    src={`${banner.image}?auto=format,compress`}
                  />
                </a>
              )
            })}
          </Slider>
          :
          <Loader />
      }
    </div>
  )
}

export default Carousel
