import React, { useEffect, useState } from 'react'
import { Layout } from '@3pp/ui-library'
import { eventTracker } from '@3pp/utils'
import { fetchData } from '@3pp/utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import Sort from 'components/Sort'
import ListSorting from 'components/ListSorting'
import NavbarBottom from 'components/NavbarBottom'
import Modal from 'react-modal'
import customHeaders from 'utils/customHeaders'


const Donations = (props) => {
  const [data, setData] = useState([])
  const [nextUrl, setNextUrl] = useState('')
  const [sortingOpen, setSortingOpen] = useState(false)

  const customStyles = {
    content : {
      top                   : '0',
      left                  : '0',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '0',
      width                 : '100%',
      zIndex: '1111'
    }
  }

  const title = {
    urgency: 'Paling Mendesak',
    latest: 'Terbaru'
  }
  let slug = ''
  switch(props.match.params.slug) {
    case 'urgency':
      slug = `?sort=urgency`
      break;
    default:
      slug = `?sort=latest`
  }
  const getNextCampaign = () => {
    const nextPage = nextUrl !== '' ? `&next=${nextUrl}` : ''
    const fetchCampaigns = async () => {
      const dataCampaign = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all${slug}${nextPage}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      })
      const newCollection = dataCampaign.data[0].next ? dataCampaign.data[0].data : data
      const collection = data.concat(newCollection)
      setNextUrl(dataCampaign.data[0].next)
      setData(collection)
    }
    fetchCampaigns()
  }
  useEffect(() => {
    const fetchCampaigns = async () => {
      const dataCampaign = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all${slug}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      })
      setNextUrl(dataCampaign.data[0].next)
      setData(dataCampaign.data[0].data)
    }
    fetchCampaigns()
  }, [props.match.params.slug, slug])
  if (data.length < 1) return null
  return (
    <Layout style={{ padding: '0 16px', background: '#fff', paddingBottom: 50 }}>
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={sortingOpen}
        onRequestClose={() =>
          setSortingOpen(false)
        }
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ width: '100%', height: '100%', background: '#f7f7f7' }}>
          <Sort
            {...props}
            onClose={() => setSortingOpen(false)}
          />
        </div>
      </Modal>
      <InfiniteScroll
        next={getNextCampaign}
        hasMore={nextUrl !== ''}
        dataLength={data.length}
        scrollThreshold="200px"
      >
        <ListSorting listCampaign={data} {...props} title={title[props.match.params.slug]} />
      </InfiniteScroll>
      <NavbarBottom
        {...props}
        onClickSorting={() => {
          eventTracker('sorting button dana')
          setSortingOpen(true)
        }}
      />
    </Layout>
  )
}

export default Donations
