import React, { useEffect, useState, Suspense} from 'react'
import Modal from 'react-modal'
import Cookies from 'js-cookie'
import { fetchData } from '@3pp/utils'
import customHeaders from 'utils/customHeaders'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { stringify } from 'query-string'
import { eventTracker } from '@3pp/utils'
import { isEmpty } from '@3pp/utils'
import Zakat from './Zakat'
import Header from 'components/common/Header'
import Layout from 'components/common/Layout'
import Carousel from 'components/common/Carousel'
import NavbarBottom from 'components/NavbarBottom'
import Survey from 'components/Survey'
import Sort from 'components/Sort'
import Shimmer from 'components/Shimmer'
import { Content } from './styles'

const CarouselCampaign = React.lazy(() => import('./CarouselCampaign'))
const ListCampaign = React.lazy(() => import('./ListCampaign'))

Modal.setAppElement('#root')

const HomeComponent = (props) => {
  const [sortingOpen, setSortingOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  const customStyles = {
    content : {
      top                   : '0',
      left                  : '0',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '0',
      width                 : '100%',
      zIndex: '1111'
    }
  }

  useEffect(() => {
    const getDanaToken = async () => {
      try {
        const { data } = await fetchData({
          url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/dana/token`,
          method: 'POST',
          body: {
            grantType: 'AUTHORIZATION_CODE',
            authCode: queryParams.authCode || queryParams.auth_code
          },
          additionalHeaders: customHeaders
        })
        const tokenResponse = data
        if (Object.hasOwnProperty.call(tokenResponse[0], 'access_token')) {
          Cookies.set('auth_token_dana', tokenResponse[0].access_token, { expires: 1 })
          setLoading(false)
        } else {
          Cookies.remove('auth_token_dana')

          setLoading(false)
        }
      } catch (error) {
        throw error
      }
    }
    if (!queryParams.authCode && !queryParams.auth_code) {
      Cookies.remove('auth_token_dana')
      setLoading(false)
    } else if (loading) {
      getDanaToken()
    }
  }, [loading, queryParams.authCode, queryParams.auth_code])

  return (
    <div>
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={sortingOpen}
        onRequestClose={() => setSortingOpen(false)}
        style={customStyles}
      >
        <div style={{ width: '100%', height: '100%', background: '#f7f7f7' }}>
          <Sort
            {...props}
            onClose={() => setSortingOpen(false)}
          />
        </div>
      </Modal>
      <Layout>
        <Header {...props} />
      </Layout>
      <Carousel banners={props.banners} {...props} />
      <Content>
        <Suspense fallback={<Shimmer />}>
          <CarouselCampaign carouselCampaign={props.carouselCampaign} {...props} />
        </Suspense>
        <Layout>
          <section>
            {
              props.listCampaign && props.carouselCampaign ? (
                <Link
                  to={`/zakat-calculator${stringifiedQuery}`}
                  onClick={() => eventTracker('zakat card dana')}>
                  <Zakat {...props}/>
                </Link>
              ) : (
                <Shimmer />
              )
            }
          </section>
          <section>
            <Suspense fallback={<Shimmer />}>
              <ListCampaign
                listCampaign={props.listCampaign} {...props}
                title="Untuk sesama"
                subtitle="Yuk, mulai bagikan kebaikan di sini"
              />
            </Suspense>
          </section>
          <Survey />
        </Layout>
      </Content>
      <NavbarBottom
        {...props}
        onClickSorting={() => {
          eventTracker('sorting button dana')
          setSortingOpen(true)
        }}
      />
    </div>
  )
}

export default HomeComponent
