import React from 'react'
import ProgressBar from '../ProgressBar'
import { currencyFormatter, eventTracker } from '@3pp/utils'
import theme from 'constants/theme'
import avatarDefault from 'styles/assets/avatar_default_circle.svg'
import { Button } from '@3pp/ui-library'
import styles from './styles.module.scss'

const Card = ({
  gtmEvent,
  title,
  avatar,

  image,
  progress=100,
  donationCollected=0,
  size='small',
  onClick=() => null,
  campaigner={
    name: '',
    verified: false,
    badge: 'https://assets.kitabisa.cc/images/icons/icon__verified-user.svg'

  },
  buttonColor=theme.color.primary,
  buttonStyle={},
  noButton,
  noProgress,
  withAvatar,
  daysRemaining

}) => {
  return (
    <>
      <div onClick={() => onClick()} className={styles[`card-${size}`]}>
        <div className={styles[`card-${size}__image-container`]}>
          <img className={styles[`card-${size}__image`]} src={image} alt={title} />
          {withAvatar && (
            avatar.length ? (
              <div className={styles[`card-${size}__campaign-avatar`]}>
                <img
                  src={avatar} alt={title}
                />
              </div>
            ) : (
              <div className={styles[`card-${size}__campaign-avatar`]}>
                <img
                  className={styles['img-default']}
                  src={avatarDefault} alt={title}
                />
              </div>
            )
          )}
        </div>
        <div className={styles[`card-${size}__meta`]}>
          <div className={styles[`card-${size}__meta-info`]}>
            <span className={styles[`card-${size}__title`]}>
              {title}
            </span>
            {
              (size === 'small' || size === 'medium' )&& (
                <ProgressBar
                  percentage={progress}
                  noProgress={noProgress}
                />
              )
            }
            {
              size !== 'fullwidth' ? (
                <>
                  <span className={styles[`card-${size}__donation-collected`]}>
                  Rp. {currencyFormatter(donationCollected)}
                  </span>
                  <div className={styles[`card-${size}__campaigner`]}>
                    <span>{campaigner.name}</span>
                    {
                      (campaigner.verified) ?
                        <img src={campaigner.badge} alt="Verified" />
                        : null
                    }
                  </div>
                </>
              ) : (
                <>
                  <div className={styles[`card-${size}__campaigner`]}>
                    <span>{campaigner.name}</span>
                    {
                      (campaigner.verified) ?
                        <img src={campaigner.badge} alt="Verified" />
                        : null
                    }
                  </div>
                  <ProgressBar
                    percentage={progress}
                    noProgress={noProgress}
                  />
                  <div className={styles[`card-${size}__list-count`]}>
                    <div className={styles[`card-${size}__list-count-item`]}>
                      <span>
                      Terkumpul
                      </span>
                      <span className={styles[`card-${size}__donation-collected`]}>
                      Rp. {currencyFormatter(donationCollected)}
                      </span>
                    </div>
                    <div className={styles[`card-${size}__list-count-item`]}>
                      <span>
                      Sisa Hari
                      </span>
                      <span className={styles[`card-${size}__donation-collected__day-left`]}>
                        {daysRemaining}
                      </span>
                    </div>
                  </div>

                </>
              )
            }

          </div>
          <div className={styles[`card-${size}__action`]}>
            {
              noButton
                ? ''
                : (
                  <Button
                    key="donasi"
                    backgroundColor={buttonColor}
                    onClick={() => {
                      onClick()
                      eventTracker(gtmEvent)
                    }}
                    label="DONASI"
                    style={buttonStyle}
                  />
                )
            }
          </div>
        </div>
      </div>
      {
        size === 'small' && (
          <div className={styles[`card-small__gap`]}></div>
        )
      }
    </>
  )
}

export default Card
