import styled, { css } from 'styled-components'

const Container = styled.div`
  background-color: transparent;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding-left: 16px;
  padding-right: 16px;
`

const Wrapper = styled.div`
  font-size: 18px;
  max-width: 225px;
  width: 100%;
  color: #757575;
  height: 50px;
  align-items: center;
  margin: 0 auto 10px;
  ${props =>
    props.center &&
    css`
      justify-content: center;
    `}
`

const IconNav = styled.img`
  color: white;
  height: 20px;
  width: 20px;
`
const NavContainer = styled.div`
  justify-content: space-around;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 20px;
  background: #3694E3;
  height: 40px;
`

const SortContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #757575;
  font-size: 14px;
  font-family: 'Open Sans Semi Bold', sans-serif;
  position: relative;
  padding-right: 20px;
  p {
    padding-left: 10px;
    color: #fff;
  }
`

const CategoryContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: #fff;
  font-size: 14px;
  font-family: 'Open Sans Semi Bold', sans-serif;
  position: relative;
  padding-left: 20px;
  p {
    padding-left: 10px;
    color: #fff;
  }
`

const Outer = styled.div`
  width: 1px;
  height: 24px;
  position: relative;
  overflow: hidden;
`

const Inner = styled.div`
  position: absolute;
  width: 100%;
  height: 40%;
  background: #757575;
  top: 30%;
  box-shadow: 0 0 0 20px #757575;
`

export {
  Container,
  Wrapper,
  SortContainer,
  IconNav,
  CategoryContainer,
  NavContainer,
  Inner,
  Outer,
}
