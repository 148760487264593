/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react'
import {
  Button,
  SwitchToggle,
  Textarea,
  FlashMessage
} from '@3pp/ui-library';
import { fetchData } from '@3pp/utils'
import theme from 'constants/theme'
import ContributeNominal from './ContributeNominal'
import queryString from 'query-string'
import DonorInfo from './DonorInfo'
import { eventTracker } from '@3pp/utils'
import LoaderImg from './assets/loader.gif'
import customHeaders from 'utils/customHeaders'
import { setOpenCodeCookie } from 'utils/computeAssignmentForVisitorId'
import {
  Divider,
  Container,
  Section,
  DonationButtonContainer
} from './styles'

const ContributePage = props => {
  const [formLoading, setFormLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [donationFailed, setDonationFailed] = useState(false)
  const [anonym, setAnonym] = useState(false)
  const [name, setName] = useState(props.history.location.state ? props.history.location.state.nameState : '')
  const [email_or_phone, setEmailOrPhone] = useState(props.history.location.state ? props.history.location.state.phoneState :'')
  const [useComment, setUseComment] = useState(false)
  const [comment, setComment] = useState('')
  const [amount, setAmount] = useState('')
  const [campaign, setCampaign] = useState(null)
  const [loading, setLoading] = useState(true)
  const [nameError, setNameError] = useState('')
  const [emailPhoneError, setEmailPhoneError] = useState('')

  const urlParams = queryString.parse(props.location.search)

  useEffect(() => {
    const fetchCampaign = async () => {
      const campaignData = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/campaign/${props.match.params.shortUrl}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      })
      setCampaign(campaignData.data[0])
      setLoading(false)
    }
    fetchCampaign()
  }, [props.match.params.shortUrl])

  const isValid = () => {
    return !!(amount && name && email_or_phone && nameError === '' && emailPhoneError === '')
  }

  const submitData = () => {
    const utmSource = urlParams.utm_source || '3pp_dana';
    const utmMedium = urlParams.utm_medium || null;
    const utmCampaign = urlParams.utm_campaign || null;
    const utmContent = urlParams.utm_content || null;

    const utm = {
      source: Array.isArray(utmSource) ? utmSource[0] : utmSource,
      medium: Array.isArray(utmMedium) ? utmMedium[0] : utmMedium,
      campaign: Array.isArray(utmCampaign) ? utmCampaign[0] : utmCampaign,
      content: Array.isArray(utmContent) ? utmContent[0] : utmContent
    }

    const generalData = {
      name,
      email_or_phone: email_or_phone.replace(/\s+$/, ''),
      payment_methods_id: 33,
      platform: 'dana',
      send_notification: true,
      redirect_callback: `${process.env.REACT_APP_KITABISA_URL}/donation/status/`,
    }
    const campaignSpecificData = {
      campaign_id: campaign.id,
      amount: Number(amount),
      comment: comment,
      campaigner_relation: false,
      is_anonymous: anonym,
      utm: {
        ...utm
      }
    }

    let body = {}
    body = {
      ...generalData,
      ...campaignSpecificData
    }

    const doSubmit = async () => {
      const submitUrl = `${process.env.REACT_APP_SEKAWAN}/v1/auth/non-login-donation`
      const headers = customHeaders

      setFormLoading(true)
      const submittedData = await fetchData({
        url: submitUrl,
        method: 'POST',
        body: body,
        additionalHeaders: headers
      })
      if (submittedData.data) {
        setOpenCodeCookie(submittedData.data[0].open_code, submittedData.data[0].id)
        setFormLoading(false)
        eventTracker('lanjutkan pembayaran contribute dana')
        window.location = submittedData.data[0]['payment_checkout_url']

      } else {
        setFormLoading(false)
        setDonationFailed(true)
        setErrorMessage(submittedData.error)
      }
    }

    if (isValid()) {
      doSubmit()
    }
  }

  const onValueChange = (values) => {
    eventTracker('field nominal contribute dana')
    setAmount(values.split('.').join(''))
  }

  if (loading) return null
  return (
    <Container>
      <FlashMessage
        colorType="orange"
        inProp={donationFailed}
        text={errorMessage}
        icon={
          <img
            src="https://assets.kitabisa.xyz/images/icon__alert--warning.svg"
            alt="icon-flash-message"
          />
        }
      />
      <ContributeNominal
        onValueChange={onValueChange}
        label="Jumlah Donasi"
        setAmount={(nominal) => setAmount(nominal)}
        amount={amount}
      />
      <div style={{ margin: '20px -16px' }}>
        <Divider />
      </div>
      <DonorInfo
        name={name}
        emailOrPhone={email_or_phone}
        emailPhoneError={emailPhoneError}
        setEmailOrPhone={setEmailOrPhone}
        setEmailPhoneError={setEmailPhoneError}
        setName={setName}
        setNameError={setNameError}
        nameError={nameError}
      />
      <Section>
        <SwitchToggle name="anonym" onChange={() => setAnonym(!anonym)} label="Sembunyikan nama saya (donasi anonim)"/>
        <SwitchToggle name="comment" onChange={() => setUseComment(!useComment)} label="Tulis komentar (opsional)"/>
        {
          (useComment) ? <Textarea onChange={setComment}/> : null
        }
        <div style={{ margin: '10px -16px' }}>
          <Divider />
        </div>
      </Section>
      <DonationButtonContainer>
        <Button
          disabled={!isValid()}
          backgroundColor={theme.color.primary}
          height="40px"
          fontSize="14px"
          margin="0 16px"
          onClick={() => {
            eventTracker('button donasi sekarang dana')
            submitData()
          }}
        >
          {
            (formLoading) ?
              <span><img src={LoaderImg} alt="Submitting Data" /></span>
              :
              <span>LANJUT PEMBAYARAN</span>
          }
        </Button>
      </DonationButtonContainer>
    </Container>
  );
};

export default ContributePage;
