/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { nominalToThousand, fetchData, eventTracker } from '@3pp/utils';
import { SwitchToggle, Button } from '@3pp/ui-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import DonorInfo from '../DonorInfo';
import ModalComponent from '../ModalComponent';
import AmilZakat from '../AmilZakat';
import Modal from 'react-modal';
import customHeaders from 'utils/customHeaders';
import Spinner from 'components/common/Spinner';
import DoaZakat from './assets/doa-zakat.svg';
import {
  Container,
  Title,
  Nominal,
  SelectMerchant,
  CtaContainer,
  CloseModal,
  ClipboardText,
  Info,
  TextZakat,
} from './style';

interface Props {
  title?: string;
  nominal?: string;
  showFlashMessage?: any;
  history?: any;
  isOpen?: boolean;
  closeModal: any;
  aboveNisab: boolean;
}

Modal.setAppElement('#root');

const Account: React.FC<Props> = props => {
  const [formLoading, setFormLoading] = useState(false);
  const [anonym, setAnonym] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [merchantModal, setMerchantModal] = useState(false);
  const [submitZakat, setSubmitZakat] = useState(false);
  const [merchantData, setMerchantData] = useState(null);
  const [nameError, setNameError] = useState('');
  const [emailPhoneError, setEmailPhoneError] = useState('');
  const [name, setName] = useState('');
  const [email_or_phone, setEmailOrPhone] = useState('');
  const [ignoreNisab, setIgnoreNisab] = useState(false);

  const getMerchant = async () => {
    setMerchantModal(true);
    const merchantData = await fetchData({
      url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?partner_id=${process.env.REACT_APP_ZAKAT_PARTNER_ID}`,
      additionalHeaders: customHeaders,
    });
    setMerchantData(merchantData.data[0]);
  };
  const validateForm = () => {
    if (selectedMerchant !== null && name !== '' && email_or_phone !== '') {
      return true;
    }
    return false;
  };
  const submitForm = async () => {
    setFormLoading(true);
    let redirectURL = `${window.location.protocol}//${window.location.host}/donation/status/`;
    const params = {
      name,
      email_or_phone: email_or_phone.replace(/\s+$/, ''),
      payment_methods_id: 33,
      platform: 'dana',
      send_notification: true,
      redirect_callback: redirectURL,
      campaign_id: selectedMerchant['id'],
      amount: Number(props.nominal),
      comment: null,
      campaigner_relation: false,
      is_anonymous: anonym,
      utm: {
        source: '3pp_dana_zakat',
        medium: null,
        campaign: null,
      },
    };
    if (validateForm()) {
      const submittedData = await fetchData({
        method: 'POST',
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/non-login-donation`,
        body: params,
        additionalHeaders: customHeaders,
      });
      if (submittedData.data) {
        setFormLoading(false);
        eventTracker('lanjutkan pembayaran contribute dana');
        window.location = submittedData.data[0]['payment_checkout_url'];
      }
    }
  };
  const onCloseModal = () => {
    props.closeModal();
    setIgnoreNisab(false);
  };
  return (
    <Container isOpen={props.isOpen} aboveNisab={props.aboveNisab || ignoreNisab}>
      <CloseModal onClick={() => onCloseModal()}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseModal>
      <Modal isOpen={merchantModal}>
        <ModalComponent
          closeModal={() => setMerchantModal(false)}
          title="Pilih Lembaga Amil Zakat"
          history={props.history}
        >
          <AmilZakat
            aboveNisab={props.aboveNisab}
            closeModal={() => setMerchantModal(false)}
            selectedMerchant={selectedMerchant}
            setSelectedMerchant={setSelectedMerchant}
            data={merchantData}
          />
        </ModalComponent>
      </Modal>
      <Modal isOpen={submitZakat}>
        <ModalComponent
          closeModal={() => setSubmitZakat(false)}
          title="Zakat Profesi"
          history={props.history}
          zakatSubmit
        >
          <img
            src={DoaZakat}
            alt="doa-zakat"
            style={{
              marginBottom: '32px',
            }}
          />
          <TextZakat>
            &quot;Semoga Allah memberikan pahala kepadamu pada barang yang engkau berikan (zakatkan)
            dan semoga Allah memberkahimu dalam harta-harta yang masih engkau sisakan dan semoga
            pula menjadikannya sebagai pembersih (dosa) bagimu&quot;
          </TextZakat>
          <Button
            backgroundColor="#3694E3"
            height="40px"
            fontSize="14px"
            margin="0 0 16px!important"
            style={{
              backgrounColor: '#3694E3',
              borderRadius: 4,
              width: 280,
              height: 40,
              border: 'none',
              color: '#ffffff',
              fontWeight: 'bold',
            }}
            onClick={() => {
              eventTracker('button amin zakat dana');
              submitForm();
            }}
            label={formLoading ? <Spinner width={30} /> : 'AAMIIN'}
          />
        </ModalComponent>
      </Modal>
      {props.aboveNisab || ignoreNisab ? (
        <>
          <Title>{props.title}</Title>
          <div style={{ position: 'relative' }}>
            <Nominal>Rp. {nominalToThousand(props.nominal)}</Nominal>
            <CopyToClipboard text={props.nominal}>
              <ClipboardText onClick={() => props.showFlashMessage()}>SALIN</ClipboardText>
            </CopyToClipboard>
          </div>
          <SelectMerchant onClick={() => getMerchant()}>
            {selectedMerchant ? (
              <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }}>
                <img src={selectedMerchant.image} alt="selected-merchant" />
                <span>{selectedMerchant.campaigner}</span>
              </div>
            ) : (
              <>
                <span>Pilih Lembaga Amil Zakat</span>
              </>
            )}
            <FontAwesomeIcon icon={faChevronDown} />
          </SelectMerchant>
          <DonorInfo
            name={name}
            emailOrPhone={email_or_phone}
            emailPhoneError={emailPhoneError}
            setEmailOrPhone={setEmailOrPhone}
            setEmailPhoneError={setEmailPhoneError}
            setName={setName}
            setNameError={setNameError}
            nameError={nameError}
          />
          <SwitchToggle
            name="anonym"
            onChange={() => setAnonym(!anonym)}
            label="Sembunyikan nama saya (donasi anonim)"
          />
          <CtaContainer>
            <Button
              disabled={!validateForm()}
              backgroundColor="#00AFEF"
              height="40px"
              fontSize="14px"
              margin="0"
              style={{
                borderRadius: 4,
                maxWidth: 480,
                margin: '0 auto',
              }}
              onClick={() => {
                eventTracker('button tunaikan zakat profesi dana');
                setSubmitZakat(true);
              }}
              label={formLoading ? <Spinner width={30} /> : 'TUNAIKAN SEKARANG'}
            />
          </CtaContainer>
        </>
      ) : (
        <>
          <h3 style={{ fontSize: 16, lineHeight: '22px', fontWeight: 600, margin: 0 }}>
            Zakat Belum Memenuhi Nisab
          </h3>
          <img src="/icons/zakat_nisab.svg" style={{ margin: '25px 0 30px' }} alt="zakat-nisab" />
          <Info>
            <span>
              Salurkan ke Lembaga Amil Zakat Resmi atau <br />
              <span className="text-blue"> program zakat</span> lainnya
            </span>
          </Info>
          <Button
            backgroundColor="#3694E3"
            height="40px"
            fontSize="14px"
            margin="0 0 16px!important"
            style={{
              borderRadius: 4,
              maxWidth: 480,
              margin: '0 auto',
            }}
            onClick={() => {
              setIgnoreNisab(true);
            }}
            label={formLoading ? <Spinner width={30} /> : 'ZAKAT SEKARANG'}
          />
          <Button
            backgroundColor="#fff"
            height="40px"
            fontSize="14px"
            margin="0"
            style={{
              color: '#3694E3',
              borderRadius: 4,
              maxWidth: 480,
              margin: '0 auto',
            }}
            onClick={() => {
              onCloseModal();
            }}
            label="HITUNG ULANG"
          />
        </>
      )}
    </Container>
  );
};

export default Account;
