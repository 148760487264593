import React from 'react'
import {
  Section,
  TextFieldWrapper,
  LabelInputError
} from './styles'
import { Input } from '@3pp/ui-library'

const DonorInfo = ({
  name,
  emailOrPhone,
  emailPhoneError,
  setEmailOrPhone,
  setEmailPhoneError,
  setName,
  setNameError,
  nameError,
}) => {
  return (
    <React.Fragment>
      <Section>
        <TextFieldWrapper>
          <Input
            style={{ backgroundColor: '#fff', border: '1px solid #E8E9EB' }}
            id="name"
            value={name}
            onChangeVal={val => {
              setName(val)
              if (val.length < 1) {
                setNameError('Nama lengkap tidak boleh kosong.')
              } else if (val.length > 70) {
                setNameError('Nama lengkap terlalu panjang (maksimal 70 karakter).')
              } else if (val && !/^[A-Za-z. ']+$/.test(val)) {
                setNameError("Nama lengkap hanya boleh huruf, titik (.) dan apostrof (')")
              } else {
                setNameError('')
              }
            }}
            placeholder="Nama Lengkap"
            errorText={nameError}
          />
        </TextFieldWrapper>
        {nameError && <LabelInputError>{nameError}</LabelInputError>}
        <TextFieldWrapper>
          <Input
            style={{ backgroundColor: '#fff', border: '1px solid #E8E9EB' }}
            id="emailOrPwd"
            value={emailOrPhone}
            placeholder="No. WhatsApp atau Email"
            onChangeVal={val => {
              setEmailOrPhone(val)
              if (val.length < 1) {
                setEmailPhoneError('Nomor WhatsApp atau Email tidak boleh kosong.')
              } else if(val.length > 50) {
                setEmailPhoneError("Karakter Nomor WhatsApp atau email terlalu panjang (maksimal 50 karakter)")
              } else if (!/^(?=.*[1-9].*)[0-9]{7,15}|([A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3})$/.test(val)) {
                setEmailPhoneError("Hanya diisi dengan nomor WhatsApp atau email.")
              } else {
                setEmailPhoneError('')
              }
            }}
            errorText={emailPhoneError}
          />
        </TextFieldWrapper>
        {emailPhoneError && <LabelInputError>{emailPhoneError}</LabelInputError>}
      </Section>
    </React.Fragment>
  )
}

export default DonorInfo
