import styled from 'styled-components'

export const Container = styled.div`
  max-width: 480px;
  width: 100%;
  margin: 10px auto 0;
`
export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 11;
`
export const NavItem = styled.a<{active?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  text-align: center;
  border-bottom: 1px solid ${props => props.active ? '#6AD248' : '#ddd'};
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.active ? '#6AD248' : '#4A4A4A'}
  img {
    width: 16px;
    height: 22px;
  }
`
export const Content = styled.div`
  padding: 16px;
`

export const Row = styled.div`
  margin-bottom: 16px;
`
export const Label = styled.span`
  display: block;
  font-size: 14px;
  line-height: 19px;
  color: #4D4D4D;
  margin-bottom: 11px;
`
export const Asterisks = styled.span`
  color: #C7265D;
`

export const CtaContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px;
`
export const Overlay = styled.div<{isOpen?: boolean;onClick: any}>`
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: ${props => props.isOpen ? '0' : '100%'};
  right: 0;
  bottom: 0;
  z-index: 11;
`
