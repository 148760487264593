import React from 'react'
import {Link} from 'react-router-dom'
import Layout from 'components/Layout'
import queryString from 'query-string'
import { isEmpty } from '@3pp/utils'
import { stringify } from 'query-string'
import { List, CheckedImg } from './style'
import { Header } from '@3pp/ui-library'
import {eventTracker} from '@3pp/utils'

const Sort = props => {
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  return (
    <>
      <Header
        onClick={props.onClose}
        title="Urutkan"
      />
      <Layout>
        <Link
          to={`/donations/urgency${stringifiedQuery}`}
        >
          <List onClick={() => {
            eventTracker('sorting-button-urgency')
            props.onClose()
          }}>
            <span>Paling Mendesak</span>
            {
              (props.match.params && props.match.params.slug === 'urgency') ?
                <CheckedImg
                  alt="check-icon"
                  src={'/icons/check.svg'}
                />
                : null
            }

          </List>
        </Link>
        <Link
          to={`/donations/latest${stringifiedQuery}`}
        >
          <List onClick={() => {
            eventTracker('sorting-button-latest')
            props.onClose()
          }}>
            <span>Terbaru</span>
            {
              (props.match.params && props.match.params.slug === 'latest') ?
                <CheckedImg
                  alt="check-icon"
                  src={'/icons/check.svg'}
                />
                : null
            }

          </List>
        </Link>
      </Layout>
    </>
  )
}

export default Sort
