import styled from 'styled-components'

export const NominalOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: rgb(74, 74, 74);
  flex-flow: wrap;
  margin: 15px -5px;
`

export const DonationLabel = styled.span`
  color: #989898;
  font-size: 14px;
  line-height: 19px;
`

export const Section = styled.div`
  margin: 10px 0;
  padding: 0 16px;
`

export const FirstLetter = styled.span`
  font-size: 16px;
  line-height: 27px;
`
export const Nominal = styled.div`
  font-size: 16px;
  line-height: 19px;
`
