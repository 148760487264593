import React from 'react'
import {
  Container,
  Wrapper,
  Header,
  Title
} from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'

interface Props {
  children: any;
  closeModal: any;
  title: string;
  zakatSubmit?: boolean;
}

const ModalComponent = (props) => {
  return (
    <Container>
      {
        !props.zakatSubmit ? (
          <>
            <Header>
              <div
                style={{
                  maxWidth: 480,
                  margin: '0 auto',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon onClick={() => props.closeModal()} icon={faArrowLeft} />
                <Title>{props.title}</Title>
              </div>
            </Header>
            <Wrapper>
              {props.children}
            </Wrapper>
          </>
        ) : (
          <>
            <Header className="zakat-submit">
              <div
                style={{
                  maxWidth: 480,
                  margin: '0 auto',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#ffffff'
                }}
              >
                <FontAwesomeIcon onClick={() => props.closeModal()} icon={faArrowLeft} />
                <Title className="zakat-title">{props.title}</Title>
              </div>
            </Header>
            <Wrapper className="zakat-content">
              {props.children}
            </Wrapper>
          </>
        )
      }

    </Container>
  )
}

export default ModalComponent
