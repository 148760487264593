import React, { useState, useEffect } from 'react'
import {
  Currency,
  TextFieldWrapper,
  TextFieldInput,
  LabelInputError,
} from './styles'
import { nominalToThousand } from '@3pp/utils'
import debounce from 'lodash.debounce'

export const DonationInput = props => {
  const [value, setValue] = useState(props.amount)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const validateInput = () => {
    if (Number(props.amount) > 0 && Number(props.amount) % 1000 !== 0) {
      setIsError(true)
      setErrorMessage('Jumlah donasi harus dalam kelipatan ribuan')
    } else if (props.amount === '') {
      setIsError(true)
      setErrorMessage('Nominal tidak boleh kosong.')
    } else {
      setIsError(false)
      setErrorMessage('')
    }
  }
  const debounced = debounce(() => validateInput(), 200)
  const onChange = event => {
    setValue(event.target.value)
    props.onChangeAmount(event.target.value)
    debounced()
  }
  useEffect(() => {
    setValue(nominalToThousand(props.amount))
  }, [props.amount, value])
  return (
    <React.Fragment>
      <TextFieldWrapper
        block={props.block}
        style={props.style}
        isError={props.isError}
      >
        <Currency>Rp</Currency>
        <TextFieldInput
          id={props.id}
          name={props.name}
          type="tel"
          value={value}
          onChange={onChange}
          onBlur={validateInput}
          autoComplete={props.autoComplete}
          isError={isError}
          disabled={props.disabled}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          required
        />
      </TextFieldWrapper>
      {isError && <LabelInputError>{errorMessage}</LabelInputError>}
    </React.Fragment>
  )
}

export default DonationInput
