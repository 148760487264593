import React from 'react'
import { SearchInput } from '@3pp/ui-library'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { isEmpty, eventTracker } from '@3pp/utils'
import { stringify } from 'query-string'
import styles from './styles.module.scss'

const Header = props => {
  if (!props) return null
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  return (
    <header className={styles.siteHeader}>
      <Link to={`/${stringifiedQuery}`} title="DANA">
        <img src="/images/logo.svg" className={styles.siteHeader__logo} alt="DANA" />
      </Link>
      <Link to={`/search${stringifiedQuery}`}>
        <SearchInput
          onClick={() => eventTracker('search bar dana')}
        >
          Cari yang ingin kamu bantu
        </SearchInput>
      </Link>
    </header>
  )
}

export default Header
