import React from 'react'
import { Link } from 'react-router-dom'
import { Header } from '@3pp/ui-library'
import queryString from 'query-string'
import { isEmpty } from '@3pp/utils'
import { stringify } from 'query-string'
import { List, CheckedImg } from './style'

const ExploreComponent = (props) => {
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  return (
    <>
      <Header
        onClick={() => props.history.goBack()}
        title="Kategori"
      />
      {props.category.map((item, index) => (
        <Link
          key={index}
          to={`/explore/${item.slug}${stringifiedQuery}`}
        >
          <List id={`category_link_kategori-${index}`} key={`category-${index}`}>
            <img alt="img-icon" src={item.icon} />
            <span>{item.name}</span>
            {props.match.params.slug === item.slug && (
              <CheckedImg
                alt="check-icon"
                src={'/icons/check.svg'}
              />
            )}
          </List>
        </Link>
      ))}
    </>
  )
}

export default ExploreComponent
