import React, { useEffect, useState} from 'react'

import { fetchData } from '@3pp/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft'
import styles from './styles.module.scss'
import { stringify } from 'query-string'
import { isEmpty } from '@3pp/utils'
import { eventTracker } from '@3pp/utils'
import queryString from 'query-string'
import Modal from 'react-modal'
import NavbarBottom from 'components/NavbarBottom'
import Card from 'components/common/Card'
import Sort from 'components/Sort'
import customHeaders from 'utils/customHeaders'

const ExplorePage = (props) => {
  const [categoryLists, setCategoryLists] = useState(null)
  const [categories, setCategories] = useState(null)
  const [sortingOpen, setSortingOpen] = useState(false)
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  useEffect(() => {
    const fetchCategoryLists = async () => {
      const dataList = await fetchData({
        url: `${process.env.REACT_APP_CORE_API}/campaign-categories`,
        method: 'GET'
      })
      setCategoryLists(dataList.data)
    }
    const fetchCategory = async () => {
      const dataCategory = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/campaigns-all?category_slug=${props.match.params.slug}`,
        method: 'GET',
        additionalHeaders: customHeaders,
      })
      setCategories(dataCategory.data[0].data)
    }
    fetchCategoryLists()
    fetchCategory()
  }, [props.match.params.slug])
  if (categoryLists === null || categories === null) return null
  const activeCategory = categoryLists.filter(item => item.slug === props.match.params.slug)[0]
  const customStyles = {
    content : {
      top                   : '0',
      left                  : '0',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '0',
      width                 : '100%',
      zIndex: '1111'
    }
  }
  return (
    <>
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={sortingOpen}
        onRequestClose={() =>
          setSortingOpen(false)
        }
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ width: '100%', height: '100%', background: '#f7f7f7' }}>
          <Sort
            {...props}
            onClose={() => setSortingOpen(false)}
          />
        </div>
      </Modal>
      <div className={styles['explore-page']}>
        <div className={styles['explore-page__header']}>
          <div className={styles['explore-page__header-wrapper']}>
            <FontAwesomeIcon onClick={() => window.history.length > 2 ? props.history.goBack() : props.history.push('/')} icon={faArrowLeft} />
            <h3 className={styles['explore-page__header-title']}>{activeCategory.name}</h3>
          </div>
        </div>
        <div className={styles['explore-page__wrapper']}>
          {categories.map(category => {
            return (
              <Card
                onClick={() => props.history.push(`/campaign/${category.short_url}${stringifiedQuery}`)}
                campaigner={{
                  name: category.campaigner,
                  verified: category.campaigner_is_verified,
                  badge: category.campaigner_badge
                }}
                gtmEvent="list campaign card dana"
                title={category.title}
                shortUrl={category.short_url}
                key={category.id}
                size="fullwidth"
                image={category.image}
                progress={category.donation_percentage * 100}
                donationCollected={category.donation_received}
                daysRemaining={category.days_remaining}
              />
            )
          })}
        </div>
        <NavbarBottom
          {...props}
          onClickSorting={() => {
            eventTracker('sorting button dana')
            setSortingOpen(true)
          }}
        />
      </div>
    </>
  )
}

export default ExplorePage
