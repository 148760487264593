import React from 'react'
import { ListCard } from '@3pp/ui-library'
import { isEmpty, eventTracker } from '@3pp/utils'
import { stringify } from 'query-string'

import queryString from 'query-string'

const ListSorting = (props) => {
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  return (
    <div style={{ marginTop: 20 }}>
      {
        (props.listCampaign !== null) ?
          props.listCampaign.map(listCampaignData => {
            return (
              <ListCard
                onClick={() => eventTracker('list campaign card dana')}
                title={listCampaignData.title}
                image={{
                  name: listCampaignData.title,
                  url: listCampaignData.image,
                }}
                shortUrl={listCampaignData.short_url}
                key={listCampaignData.id}
                campaigner={{
                  name: listCampaignData.campaigner,
                  type: listCampaignData.campaigner_type
                }}
                progressBar={{
                  color: 'primary',
                  percentage: listCampaignData.donation_percentage * 100
                }}
                progress={listCampaignData.donation_percentage * 100}
                remainingDays={listCampaignData.days_remaining}
                donationCollected={listCampaignData.donation_received}
                permalink={`/campaign/${listCampaignData.short_url}${stringifiedQuery}`}
              />
            )
          })
          : null
      }
    </div>
  )
}

export default ListSorting
