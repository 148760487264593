import React from 'react'
import Profesi from './Profesi'
import {
  Container,
  Content
} from './style'
import './styles.scss'

const Zakat = (props) => {
  return (
    <Container>
      <Content>
        <Profesi history={props.history} {...props} />
      </Content>
    </Container>
  )
}

export default Zakat
