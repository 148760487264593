import React from 'react'
import styles from './styles.module.scss';

const Shimmer = () => {
  return (
    <div className={styles.loading__animate}></div>
  )
}

export default Shimmer
