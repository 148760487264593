import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styles from './styles.module.scss'

const Zakat = () => {
  return (
    <div>
      <h3>
        Tunaikan Zakat
      </h3>
      <span className={styles.subitle}>
        Mulai hitung kewajiban zakatmu di sini
      </span>
      <div
        style={{ marginTop: 16, display: 'block' }}
        title="zakat"
      >
        <LazyLoadImage
          alt="zakat"
          width={450}
          src="/uploads/zakat.svg"
        />
      </div>
    </div>
  )
}

export default Zakat
