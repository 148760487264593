import React from 'react'
import queryString from 'query-string'
import { isEmpty } from '@3pp/utils'
import { stringify } from 'query-string'
import {
  Container,
  Wrapper,
  SortContainer,
  IconNav,
  CategoryContainer,
  NavContainer,
  Inner,
  Outer,
} from './style'
import { eventTracker } from '@3pp/utils'
import sortingIcon from './assets/Sort.svg'
import filterIcon from './assets/Filter.svg'

export const NavbarBottom = props => {
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  const onClickCategory = () => {
    eventTracker('category filter button dana')
    props.history.push(`/explore${stringifiedQuery}`)
  }
  return (
    <Container>
      <Wrapper>
        <NavContainer>
          <CategoryContainer onClick={() => onClickCategory()}>
            <IconNav src={filterIcon} alt="category" />
            <p>Kategori</p>
          </CategoryContainer>
          <Outer>
            <Inner />
          </Outer>
          <SortContainer onClick={() => props.onClickSorting()}>
            <IconNav src={sortingIcon} alt="sort" />
            <p>Urutkan</p>
          </SortContainer>
        </NavContainer>
      </Wrapper>
    </Container>
  )
}

export default NavbarBottom
